import { flare } from '@flareapp/flare-client';
import { arrayIncludesAny } from '@/utilities';

if (import.meta.env.PROD) {
    flare.light();
}

flare.beforeSubmit = (report) => {

    console.log(report);

    if (filterMessage(report) === false) {
        return false;
    }

    if (filterUserAgent(report) === false) {
        return false;
    }

    return report;
};

function filterMessage(report) {
    const message = report.message;


    return !arrayIncludesAny(message, [
        'Failed to fetch dynamically imported',
        'Unable to preload CSS',
        'Importing a module script failed.',
        'Request aborted',
    ]);
}

function filterUserAgent(report) {
    const userAgent = report.context.request.useragent;
    // Don't send errors from bots
    return !arrayIncludesAny(userAgent, ['adsbot', 'googlebot', 'Applebot']);
}

export default {
    flare,
};
