import DataObjectParser from 'dataobject-parser';
import { pickBy } from 'lodash';

export function objectMap(obj, fn) {
    return Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));
}

export function mapErrors(errors, remap = {}) {
    errors = objectMap(errors, (error) => [error]);
    errors = DataObjectParser.transpose(errors).data();

    for (const [key, value] of Object.entries(remap)) {
        if (Object.hasOwnProperty.call(remap, key)) {
            errors[key] = errors[value];
        }
    }
    console.table(errors);
    return errors;
}

export function filterEmpty(data) {
    return pickBy(data, (v, k, i) => v !== '' && v !== null);
}

export function getTimesBetween(start, end, step = 'day', stepSize = 1) {
    let localStart = start;
    let dates = [];
    while (localStart.isBefore(end)) {
        dates.push(localStart);
        localStart = localStart.add(stepSize, step);
    }
    return dates;
}

export async function precognition(form, url) {
    form.clearErrors();
    return await window.axios
        .post(url, form.data(), { headers: { Precognition: true } })
        .then((res) => {
            return true;
        })
        .catch(function(error) {
            if (error?.response?.status === 422) {
                form.setError(error.response.data.errors);
            }
            return false;
        });
}

export function pick(obj, fields) {
    return fields.reduce((acc, cur) => ((acc[cur] = obj[cur]), acc), {});
}

export function formatMoney(cents, locale = '') {
    let string = String((parseInt(cents) / 100).toFixed(2));

    // TODO Add better solution for locale. Passing it on each call seems strange.
    if (locale == 'nl') {
        return string.replace('.', ',');
    }
    return string;
}

export function secondsToTime(seconds) {
    let hoursPart = String(Math.floor(seconds / 3600)).padStart(2, '0');
    let minutesPart = String(Math.floor((seconds % 3600) / 60)).padStart(2, '0');
    return hoursPart + ':' + minutesPart;
}

export function timeToSeconds(seconds) {
    let array = seconds.split(':');
    return 1 * array[0] * 3600 + 1 * array[1] * 60;
}

// Vite cache busting
// https://stackoverflow.com/a/68123263/6365367
export const hash = Math.floor(Math.random() * 90000) + 10000;

export const capitalizeFirstLetter = (string) => {
    if (string === null) {
        return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const ucFirst = (string) => {
    return capitalizeFirstLetter(string);
};


export function arrayUnique(array, fn) {
    return array.filter((e, i) => array.findIndex(a => fn(a, e)) === i);
}

export function arrayIncludesAny(array, values) {
    array = array.map((value) => value.toLowerCase());
    values = values.map((value) => value.toLowerCase());

    return values.some((value) => array.includes(value));
}


export function blank(value) {
    console.log(value);
    return value === '' || value === null || value === undefined;
}
